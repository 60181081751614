import { useCallback, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { RuntypeBrand } from "runtypes";

import { useSetEditMode } from "../hooks/useEditMode";

export const useIsDialogOpen = (dialog?: string): boolean => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  if (dialog) {
    return queryParams.get("dialog") === dialog;
  } else {
    return queryParams.get("dialog") != null;
  }
};

type UseDialog = (
  dialog: string,
  /**
   * If there are several dialogs of the same type in the component tree, its useful to provide a unique identifier
   * so that the different dialogs can be de-duped, and the correct one can be rendered.
   */
  uniqueId?: RuntypeBrand<any> & string,
) => {
  openDialog: (args?: {
    shouldReturnToEditModeOnClose?: boolean;
    /**
     * An optional callback to use to apply extra modifications to the current instance of `URLSearchParams` before the `dialog` query param is set.
     * `beforeSetDialogQueryParam` is to be used only after all other options to provide state to the component have been exhausted.

    ```tsx
      const handleOnDialogOpen = () => {
        openDialog(undefined (queryParams) => {
          queryParams.set('hexId', 'xxx-xxxx-xxx');
        });
      }
    ```
    */
    beforeSetDialogQueryParam?: (queryParams: URLSearchParams) => void;
  }) => void;
  closeDialog: (args?: {
    navigate?: boolean;
    /**
     * Override the navigation method used to close the dialog.
     * Defaults to either push or goBack depending on whether the dialog was opened via this hook or not
     */
    navigationMethod?: "push" | "replace";
    /**
     * An optional callback to use to apply extra modifications to the current instance of `URLSearchParams` before the `dialog` query param is deleted.
     * `beforeDeleteDialogSearchParam` is to be used only after all other options provide state to the component have been exhausted.

    ```tsx
      const handleOnDialogClose = () => {
        closeDialog({navigate: true}, (queryParams) => {
          queryParams.delete('hexId');
        })
      }
    ```
    */
    beforeDeleteDialogSearchParam?: (queryParams: URLSearchParams) => void;
  }) => void;
  dialogKey: number;
  isOpen: boolean;
  toggleDialog: () => void;
  setDialogOpen: (open: boolean) => void;
};

export const useDialog: UseDialog = (_dialog, uniqueId) => {
  const dialogId = uniqueId != null ? `${_dialog}-${uniqueId}` : `${_dialog}`;

  const history = useHistory();
  // Whether or not this was opened via this hook, to avoid polluting browser history
  const [wasOpened, setWasOpened] = useState(false);
  const [returnToEditModeOnClose, setReturnToEditModeOnClose] = useState(false);
  const setEditMode = useSetEditMode();

  const openDialog: ReturnType<UseDialog>["openDialog"] = useCallback(
    ({
      beforeSetDialogQueryParam,
      shouldReturnToEditModeOnClose = false,
    } = {}) => {
      setReturnToEditModeOnClose(shouldReturnToEditModeOnClose);
      const location = history.location;
      const queryParams = new URLSearchParams(location.search);
      beforeSetDialogQueryParam?.(queryParams);
      queryParams.set("dialog", dialogId);

      location.search = `?${queryParams.toString()}`;
      setWasOpened(true);
      history.push(location);
    },
    [dialogId, history],
  );

  const closeDialog: ReturnType<UseDialog>["closeDialog"] = useCallback(
    ({
      beforeDeleteDialogSearchParam,
      navigate = true,
      navigationMethod,
    } = {}) => {
      if (returnToEditModeOnClose) {
        setEditMode(true);
      }
      const queryParams = new URLSearchParams(history.location.search);
      if (queryParams.get("dialog") === dialogId) {
        const location = history.location;
        beforeDeleteDialogSearchParam?.(queryParams);
        queryParams.delete("dialog");

        location.search = `?${queryParams.toString()}`;
        if (navigate) {
          if (wasOpened) {
            setWasOpened(false);
            // If the dialog was opened via this hook, we typically want to just use `goBack`
            // to avoid polluting the browser history with a lot of duplicate entries
            // However, if while the dialog was open, something used push to navigate,
            // calling `goBack` will not work as expected, as it will leave the dialog open.
            // In this case, we can use the `navigationMethod` prop to override this behavior.
            navigationMethod === "push"
              ? history.push(location)
              : navigationMethod === "replace"
                ? history.replace(location)
                : history.goBack();
          } else {
            history.push(location);
          }
        }
      }
    },
    [dialogId, history, returnToEditModeOnClose, setEditMode, wasOpened],
  );

  const isOpen = useIsDialogOpen(dialogId);
  const [prevIsOpen, setPrevIsOpen] = useState(isOpen);
  const [dialogKey, setDialogKey] = useState(Math.random());
  // This allows us to set a unique key for the dialog whenever it is opened
  if (prevIsOpen !== isOpen) {
    setPrevIsOpen(isOpen);
    if (isOpen) {
      setDialogKey(Math.random());
    }
  }
  const setDialogOpen: ReturnType<UseDialog>["setDialogOpen"] = useCallback(
    (open: boolean) => {
      open ? openDialog() : closeDialog();
    },
    [closeDialog, openDialog],
  );
  const toggleDialog: ReturnType<UseDialog>["toggleDialog"] =
    useCallback(() => {
      isOpen ? closeDialog() : openDialog();
    }, [closeDialog, isOpen, openDialog]);

  return {
    openDialog,
    isOpen,
    closeDialog,
    setDialogOpen,
    toggleDialog,
    dialogKey,
  };
};
