import {
  BatchClientAnalyticsEvent,
  BatchClientAnalyticsTuples,
  CONTENT_TYPE_HEADER,
  CONTENT_TYPE_VALUE_JSON,
  ClientAnalyticsEventType,
} from "@hex/common";

import { ORG_ID } from "../orgs";

import { ClientCounterEvent } from "./clientCounterEvent";
import { createRequestHeaders } from "./headers";

export const TRACK_EVENT_TIMEOUT_MS = 1000;

let queuedEvents: BatchClientAnalyticsEvent[] = [];
let currentTimeout: number | undefined;

export const trackEvent = <K extends BatchClientAnalyticsTuples>(
  ...[event, properties]: K
): void => {
  queuedEvents.push({ event, properties } as BatchClientAnalyticsEvent);

  if (currentTimeout == null) {
    currentTimeout = window.setTimeout(
      () => sendEvents(),
      TRACK_EVENT_TIMEOUT_MS,
    );
  }
};

export const trackCounterEvent = (eventType: ClientCounterEvent): void => {
  trackEvent(ClientAnalyticsEventType.CLIENT_COUNTER_EVENT, {
    eventType,
  });
};

/**
 * This is invoked from the highest level component to ensure that we will send
 * all events before session closes.
 */
export const sendEvents = (): void => {
  window.clearTimeout(currentTimeout);
  currentTimeout = undefined;

  if (queuedEvents.length === 0) {
    return;
  }

  void fetch("/track-events", {
    // setting keepalive to true to ensure that the request is sent even if
    // session ends.
    keepalive: true,
    method: "POST",
    headers: {
      [CONTENT_TYPE_HEADER]: CONTENT_TYPE_VALUE_JSON,
      ...createRequestHeaders(),
    },
    body: JSON.stringify({
      events: queuedEvents,
      orgId: ORG_ID,
    }),
  });
  queuedEvents = [];
};
